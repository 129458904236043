import { ChevronLeftIcon, ChevronRightIcon, StarIcon } from "@heroicons/react/outline";
import { StarIcon as SolidStarIcon } from "@heroicons/react/solid";
import { Spinner, WithSignIn } from "components";
import { PhotosListingDisclosure } from "components/browse";
import { PinIcon } from "components/icons";
import { StreetView } from "components/proformas/details";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useUserSession } from "state";
import { useSaveDeal } from "state/proformas";

type Props = {
  property: any;
};

export const PropertyPhotos = ({ property }: Props) => {
  const [photoIdx, setPhotoIdx] = useState(0);
  const [mouseOver, setMouseOver] = useState(false);
  const [saving, setSaving] = useState(false);
  const currentPhoto = (property.photo_urls && property.photo_urls[photoIdx]) || property.photo || null;
  const inErrorImage = "https://cdn.davinci.pellego.com/static/images/core/missing.png";
  const { isSavedDeal, saveDeal, deleteSavedDeal } = useSaveDeal();
  const currentUser = useUserSession();

  // Cache and preload the next and previous two photos of the property so as to avoid flickering when changing photos
  useEffect(() => {
    // Add to cache first next/prev photo
    const nextPhoto = new Image();
    nextPhoto.src = property.photo_urls[(photoIdx + 1) % property.photo_urls.length];
    const prevPhoto = new Image();
    prevPhoto.src = property.photo_urls[photoIdx > 0 ? photoIdx - 1 : property.photo_urls.length - 1];
    // Remove comment in next lines to add to cache second next/prev photo
    // const next2Photo = new Image();
    // next2Photo.src = property.photo_urls[(photoIdx + 2) % property.photo_urls.length];
    // const prev2Photo = new Image();
    // prev2Photo.src = property.photo_urls[photoIdx > 1 ? photoIdx - 2 : property.photo_urls.length - 2];
  }, [photoIdx, property.photo_urls]);

  const onClickNext = (e: React.MouseEvent) => {
    e.preventDefault();
    setPhotoIdx((photoIdx + 1) % property.photo_urls.length);
  };

  const onClickPrev = (e: React.MouseEvent) => {
    e.preventDefault();
    setPhotoIdx(photoIdx > 0 ? photoIdx - 1 : property.photo_urls.length - 1);
  };

  const onSaveProperty = (e: React.MouseEvent) => {
    e.preventDefault();
    setSaving(true);
    saveDeal(property._id, property.parcel_id).finally(() => setSaving(false));
  };

  const onUnSaveProperty = (e: React.MouseEvent) => {
    e.preventDefault();
    setSaving(true);
    deleteSavedDeal(property.parcel_id).finally(() => setSaving(false));
  };

  return (
    <div
      className="bg-[background-size: 100% 100%;] flex h-full w-full items-end rounded-t-md bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url('${currentPhoto}')` }}
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
    >
      {!currentPhoto && (
        <StreetView
          otherClasses="flex cursor-pointer items-end"
          sizeClasses="rounded-t-md bg-cover bg-center bg-no-repeat size-full"
          parcel={property}
          titles={false}
          rounded="rounded-t-sm"
          inErrorImage={inErrorImage}
        />
      )}
      <PhotosListingDisclosure property={property} />
      <div className="absolute top-0 z-10 flex flex-col">
        {property.assumable_loan && (
          <div className="mx-2 mt-1 flex">
            <div className="rounded-md bg-black px-2 py-1 text-[9px] text-white">Assumable Loan</div>
          </div>
        )}
        {property.sqftUnfinished > 0 && (
          <div className="mx-2 mt-1 flex">
            <div className="rounded-md bg-black px-2 py-1 text-[9px] text-white">Unfinished Sqft</div>
          </div>
        )}
        {property.listingType !== "Standard" && (
          <div className="mx-2 mt-1 flex">
            <div className="rounded-md bg-black px-2 py-1 text-[9px] text-white">{property.listingType}</div>
          </div>
        )}
      </div>
      {currentUser.isClient && property.is_wholesale && (
        <div className="absolute left-2 top-0 z-10 mt-1 flex rounded-md bg-black px-2 py-1">
          <div className="justify-left flex items-center">
            <PinIcon className="z-10 mr-2 size-3 text-white" />
            <div className="text-[9px] text-white">Assignment Contract</div>
          </div>
        </div>
      )}

      <div className="absolute right-0 top-0 z-10 mt-2 flex px-2 py-0">
        <WithSignIn useChildren>
          {saving ? (
            <Spinner className="ml-2 h-5 w-5 text-white" />
          ) : (
            <>
              {isSavedDeal(property.parcel_id) ? (
                <SolidStarIcon
                  onClick={onUnSaveProperty}
                  className="align-right z-10 ml-2 h-5 w-5 text-white shadow-xl"
                  aria-hidden="true"
                />
              ) : (
                <StarIcon
                  onClick={onSaveProperty}
                  className="align-right z-10 ml-2 h-5 w-5 text-white shadow-xl"
                  aria-hidden="true"
                />
              )}
            </>
          )}
        </WithSignIn>
      </div>

      {(mouseOver || isMobile) && (
        <>
          <button
            className="z-9 bg-black/1 absolute left-0 h-full w-20 items-center px-0 py-0"
            onClick={onClickPrev}
          >
            <ChevronLeftIcon
              className="align-left hover:text-sl-lightest z-10 ml-0 h-8 w-8 text-white drop-shadow-md"
              aria-hidden="true"
            />
          </button>
          <button
            className="z-9 bg-black/1 absolute right-0 flex h-full w-20 items-center justify-end px-0 py-0 text-right"
            onClick={onClickNext}
          >
            <ChevronRightIcon
              className="align-right hover:text-sl-lightest z-10 mr-0 h-8 w-8 text-white drop-shadow-md"
              aria-hidden="true"
            />
          </button>
        </>
      )}

      <div className="top-30 absolute h-12 w-full bg-gradient-to-t from-gray-900/80 to-transparent"></div>
    </div>
  );
};
