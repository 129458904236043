import { XIcon } from "@heroicons/react/solid";
import { yupResolver } from "@hookform/resolvers/yup";
import { mdiCameraRetakeOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { useUserActions } from "actions";
import { Alert, ChangePassword, FormFieldError, ModalWrapper, ProfilePictureUploader } from "components";
import { EditAreasOfService } from "components/agents";
import { Checkbox, LabeledInput, LabeledSelect, SubmitButton } from "components/forms";
import {
  CALIFORNIA_STATE_ID,
  COLORADO_STATE_ID,
  FLORIDA_STATE_ID,
  ILLINOIS_STATE_ID,
  isPellego,
  TEXAS_STATE_ID,
  WASHINGTON_STATE_ID,
} from "config";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAlerts, useUserSession } from "state";
import * as Yup from "yup";

const skills = [
  "flips",
  "rentals",
  "short term rentals",
  "single family",
  "multi family",
  "condos",
  "townhomes",
  "mobile homes",
  "land development",
  "new construction",
  "DADUs",
  "off market",
  "commercial",
];

export const Account = () => {
  const currentUser = useUserSession();
  const userActions = useUserActions();
  const [showPictureUploaderModal, setShowPictureUploaderModal] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [serviceAreas, setServiceAreas] = useState(currentUser.session?.service_areas || []);
  const alert = useAlerts();

  const defualtRegionOptions = [
    { name: "-- select --", value: "" },
    { name: "California", value: CALIFORNIA_STATE_ID },
    { name: "Washington", value: WASHINGTON_STATE_ID },
    { name: "Florida", value: FLORIDA_STATE_ID },
    { name: "Illinois", value: ILLINOIS_STATE_ID },
    { name: "Texas", value: TEXAS_STATE_ID },
    { name: "Colorado", value: COLORADO_STATE_ID },
  ];

  // form validation rules
  const validationSchema = Yup.object().shape({
    name_first: Yup.string().required("Please enter your first name"),
    name_last: Yup.string().required("Please enter your last name"),
    phone_number: Yup.string(),
    default_region: Yup.string().required("Please select a default region"),
    description: Yup.string(),
    skills: Yup.array()
      .of(Yup.string())
      .test("len", "Select up to 5 items", (val: any) => val.length <= 5),
    service_areas: Yup.array().of(Yup.object()),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name_first: currentUser.session?.name_first,
      name_last: currentUser.session?.name_last,
      phone_number: currentUser.session?.phone_number || "",
      default_region: currentUser.session?.default_region,
      description: currentUser.session?.description,
      skills: currentUser.session?.skills || [],
      service_areas: currentUser.session?.service_areas || [],
    },
  };

  const { register, handleSubmit, setFocus, formState, setValue } = useForm(formOptions);
  const { errors, isSubmitting, isSubmitSuccessful } = formState;

  useEffect(() => {
    setFocus("name_first");
  }, [setFocus]);

  const updateServiceAreas = (areas: any[]) => {
    setServiceAreas(areas);
    setValue("service_areas", areas);
  };

  const closeChangePasswordModal = () => {
    setShowChangePasswordModal(false);
    alert.clear();
  };

  return (
    <div className="mx-auto mt-8 max-w-md">
      <h1 className="mb-6 text-center text-3xl">Edit Account</h1>
      <div className="grid grid-cols-1 place-items-center">
        <div className="relative m-3 rounded-xl p-3">
          <span
            className="inline-block h-40 w-40 rounded-full bg-cover bg-center bg-no-repeat"
            style={{ backgroundImage: `url(${currentUser.avatar})` }}
          ></span>
          <div
            className="flex w-full cursor-pointer flex-row items-center justify-center border border-white p-1 text-center text-xs text-st-lightest hover:border-gray-300"
            onClick={() => setShowPictureUploaderModal(true)}
          >
            <Icon className="h-5 w-5 pr-1 text-st-lightest" path={mdiCameraRetakeOutline} />
            Edit Profile Picture
          </div>
        </div>
        <div className="mt-1 text-center">
          <p className="text-2xl font-bold">
            {currentUser.session?.name_first} {currentUser.session?.name_last}
          </p>
          <p className="text-sm">{currentUser.session?.email}</p>

          <div
            className="mt-2 flex w-full cursor-pointer flex-row items-center justify-center border border-gray-300 p-1 text-center text-xs text-st-lighter hover:bg-gray-100"
            onClick={() => setShowChangePasswordModal(true)}
          >
            Change your password
          </div>
        </div>
      </div>

      <hr className="mb-1 mt-10" />

      <Alert />

      <div className="m-6 grid grid-cols-1 gap-6">
        <form onSubmit={handleSubmit(userActions.updateUser)}>
          <LabeledInput
            type="text"
            label="First Name"
            {...register("name_first")}
            errMsg={errors.name_first?.message}
          />
          <LabeledInput
            type="text"
            label="Last Name"
            {...register("name_last")}
            errMsg={errors.name_last?.message}
          />
          <LabeledInput
            type="text"
            label="Phone"
            {...register("phone_number")}
            errMsg={errors.phone_number?.message}
          />

          <LabeledSelect
            label="Default Region"
            {...register("default_region")}
            errMsg={errors.default_region?.message}
          >
            {defualtRegionOptions.map((opt) => (
              <option key={opt.value} value={opt.value}>
                {opt.name}
              </option>
            ))}
          </LabeledSelect>

          {currentUser.session?.agent_slug && (
            <>
              <div className="mt-8 w-full border p-4">
                <div className="text-lg font-bold">Public Profile</div>
                <div className="text-sm font-normal text-st-light">
                  Share your public profile page with your prospect customers. Just fill up these fields and
                  you'll instantly get your very own agent profile page at no cost.
                </div>

                <div className="mt-4 border-b pb-4 pt-2 text-sm font-bold text-st-darker">
                  Visit your agent profile page at:
                  <div className="font-normal lowercase text-sl-dark">
                    <a href={`/agents/${currentUser.session?.agent_slug}`}>
                      app.{isPellego ? "pellego" : "lotside"}.com/agents/{currentUser.session?.agent_slug}
                    </a>
                  </div>
                </div>

                <div className="my-2 mt-4 flex-1">
                  <span className="mx-0 text-sm font-bold text-st-normal">Public profile description</span>
                  <textarea
                    placeholder="Enter a brief bio so prospect clients con know how you can help them as a real estate agent. This text will be the main description in you public profile."
                    className="text-grey-800 my-1 flex h-80 w-full border-gray-400 bg-transparent p-4"
                    {...register("description")}
                  ></textarea>
                </div>

                <div className="mx-0 mt-6 flex-1">
                  <div className="text-sm font-bold text-st-darker">
                    Areas of service
                    <EditAreasOfService serviceAreas={serviceAreas} updateServiceAreas={updateServiceAreas} />
                  </div>
                </div>

                <div className="mx-0 mt-6 flex-1">
                  <div className="text-sm font-bold text-st-darker">Select 5 areas of focus:</div>
                  <div className="mb-2 text-sm font-normal text-st-light">
                    Show your customers the five areas where you excel as a real estate agent.
                  </div>
                  <FormFieldError className="mt-0" field={errors.skills} />
                  <div className="text-sm capitalize">
                    {skills.map((skill) => (
                      <Checkbox value={skill} key={skill} {...register("skills")}>
                        {skill}
                      </Checkbox>
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="w-full text-center">
            <SubmitButton isSubmitting={isSubmitting} isSuccessful={isSubmitSuccessful && !isSubmitting}>
              Update
            </SubmitButton>
          </div>
        </form>
      </div>

      <ModalWrapper isOpen={showPictureUploaderModal} closeModal={() => setShowPictureUploaderModal(false)}>
        <div className="inline-block w-full max-w-sm transform overflow-hidden rounded-lg border border-gray-200 bg-white p-2 text-left align-middle shadow-xl transition-all">
          <header className="flex w-full flex-row items-center">
            <div className="text-md mb-2 flex basis-4/5 items-center pl-5 font-medium text-st-normal">
              Upload a new profile picture
            </div>
            <div className="justify-right basis-1/5 pr-4 text-right">
              <button
                type="button"
                className="rounded-md pb-1 pl-1 text-sm text-st-lightest hover:border-gray-400 hover:bg-gray-400 hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-focus-ring focus-visible:ring-offset-2"
                onClick={() => setShowPictureUploaderModal(false)}
              >
                <XIcon className="mr-1 mt-1 w-7" aria-hidden="true" />
              </button>
            </div>
          </header>
          <ProfilePictureUploader onUploaded={() => setShowPictureUploaderModal(false)} />
        </div>
      </ModalWrapper>

      <ModalWrapper isOpen={showChangePasswordModal} closeModal={closeChangePasswordModal}>
        <div className="inline-block w-full max-w-sm transform overflow-hidden rounded-lg border border-gray-200 bg-white p-2 text-left align-middle shadow-xl transition-all">
          <header className="flex w-full flex-row items-center">
            <div className="text-md mb-2 flex basis-4/5 items-center pl-5 font-medium text-st-normal">
              Change your password
            </div>
            <div className="justify-right basis-1/5 pr-4 text-right">
              <button
                type="button"
                className="rounded-md pb-1 pl-1 text-sm text-st-lightest hover:border-gray-400 hover:bg-gray-400 hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-focus-ring focus-visible:ring-offset-2"
                onClick={closeChangePasswordModal}
              >
                <XIcon className="mr-1 mt-1 w-7" aria-hidden="true" />
              </button>
            </div>
          </header>
          <ChangePassword onClose={closeChangePasswordModal} />
        </div>
      </ModalWrapper>
    </div>
  );
};
