import { isMobile } from "react-device-detect";
import { Control, Controller, FieldValues, UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { cn } from "../../helpers";
import { FilterDropdownMenu } from "./FilterDropdownMenu";

type Props = {
  label: string;
  variable: string;
  isCurrency?: boolean;
  isPercentage?: boolean;
  control: Control<FieldValues, unknown>;
  handleChange: (e: any) => void;
  setValue: UseFormSetValue<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  options: number[];
};

export const MinMaxFilterDropdowns = ({
  label,
  variable,
  isCurrency,
  isPercentage,
  control,
  handleChange,
  setValue,
  getValues,
  options,
}: Props) => {
  const commonClasses = "flex h-9 text-right border-dotted border-gray-400 items-center border px-2 py-4";

  return (
    <div className="mt-2 flex w-full flex-row items-center justify-between gap-1 sm:gap-4">
      <div className="relative flex w-1/3 whitespace-nowrap">
        {variable === "equity" ? "Equity" : label}
        {/* Bagde */}
        {variable === "equity" && (
          <>
            <span className="sr-only">New filter badge</span>
            <span
              className={cn(
                "ml-2 mt-1 inline-flex h-4 w-fit items-center justify-center rounded-sm border-red-500 bg-red-500 px-2 text-[10px] font-bold text-white",
              )}
            >
              {isMobile ? "NEW" : "NEW FILTER"}
            </span>
          </>
        )}
      </div>
      <div className="flex w-2/3 flex-row gap-1 sm:gap-4">
        <div className="flex w-full">
          <Controller
            control={control}
            name={`min_${variable}`}
            render={({ field: { onChange, name, value } }) => (
              <FilterDropdownMenu
                className={commonClasses}
                placeholder="Min"
                label={label}
                value={value}
                options={
                  getValues(`max_${variable}`)
                    ? options.filter((o) => o < getValues(`max_${variable}`))
                    : options
                }
                onChange={(min_value: any) => {
                  onChange(min_value);
                  setValue(name, min_value);
                  handleChange(min_value);
                }}
              />
            )}
          />
        </div>
        <div className="text-st-lightest py-1">-</div>
        <div className="flex w-full">
          <Controller
            control={control}
            name={`max_${variable}`}
            render={({ field: { onChange, name, value } }) => (
              <FilterDropdownMenu
                className={commonClasses}
                placeholder={"Max"}
                label={label}
                value={value}
                options={
                  getValues(`min_${variable}`)
                    ? options.filter((o) => o > getValues(`min_${variable}`))
                    : options
                }
                onChange={(max_value: any) => {
                  onChange(max_value);
                  setValue(name, max_value);
                  handleChange(max_value);
                }}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};
