export const fetcherWithCredentials = async (
  urlOrArray: any,
  method: "GET" | "POST" | "PUT" | "DELETE" = "GET",
  body: any = undefined,
  signal?: AbortSignal,
) => {
  const requestOptions: RequestInit = { method, credentials: "include" };
  if (body) {
    requestOptions.headers = { "Content-Type": "application/json" };
    requestOptions.body = JSON.stringify(body);
  }
  if (signal) {
    requestOptions.signal = signal;
  }

  try {
    const url = Array.isArray(urlOrArray) ? urlOrArray[0] : urlOrArray;
    const response = await fetch(url, requestOptions);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    if (!(error instanceof Error && error.name === "AbortError")) {
      console.error("Error in fetcherWithCredentials:", error);
    }
    throw new Error(`Failed to fetch: ${error as string}`);
  }
};

export const fetcher = (url: string) => fetch(url).then((res) => res.json());
