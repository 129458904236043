import { calculateEquityData, PieChartSection, RightColumn, TooltipSection } from "components/proformas";
import { type LoanSpecs } from "components/proformas/details";
import React, { useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import { useProforma } from "state/proformas";

export const EquityGraph = ({ loansSpecs }: { loansSpecs: LoanSpecs }) => {
  const [selectedSector, setSelectedSector] = useState<number | null>(null);
  const { proforma } = useProforma();

  const {
    lastSalePrice,
    loansSpecsFiltered,
    marketValue,
    totalOriginalPrincipals,
    totalRemainingPrincipals,
    totalPayDowns,
    appreciation,
    equity,
    data,
  } = useMemo(() => calculateEquityData(proforma, loansSpecs), [proforma, loansSpecs]);

  if (loansSpecsFiltered.length === 0 && proforma.parcel?.mls_only_listing_number !== null) return null;

  const LoadingLines = () => {
    const lines = Array.from({ length: 6 }, (_, index) => (
      <div key={index} className="h-5 w-full rounded-md bg-gray-400/10 loading" />
    ));
    return <div className="flex w-full max-w-52 flex-col gap-3">{lines}</div>;
  };

  return (
    <>
      {!proforma.isRestError && (
        <>
          {!proforma.isDataComplete ? (
            <div
              className={`${isMobile && "flex-col items-center"} mt-10 flex w-full flex-row items-start sm:flex-col sm:items-center md:flex-row md:items-start lg:flex-col lg:items-center xl:flex-row xl:items-start`}
            >
              <div className="mb-4 mr-4 size-[192px] shrink-0 rounded-full bg-gray-400/10 loading" />
              <LoadingLines />
            </div>
          ) : (
            <div className="w-full">
              <div className="mt-6 flex items-center text-xs font-bold uppercase text-green-900">
                Estimated Equity
              </div>
              <div
                className={`mt-4 flex w-full ${isMobile ? "flex-col items-center" : "flex-row items-center sm:flex-col md:flex-row lg:flex-col xl:flex-row"}`}
              >
                <div className="relative flex w-full items-center justify-center">
                  <PieChartSection
                    data={data}
                    selectedSector={selectedSector}
                    setSelectedSector={setSelectedSector}
                    equity={equity}
                    marketValue={marketValue}
                  />
                  <TooltipSection
                    selectedSector={selectedSector}
                    data={data}
                    loansSpecsFiltered={loansSpecsFiltered}
                  />
                </div>
                <RightColumn
                  loansSpecsFiltered={loansSpecsFiltered}
                  marketValue={marketValue}
                  lastSalePrice={lastSalePrice}
                  appreciation={appreciation}
                  totalRemainingPrincipals={totalRemainingPrincipals}
                  totalOriginalPrincipals={totalOriginalPrincipals}
                  totalPayDowns={totalPayDowns}
                  equity={equity}
                />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
