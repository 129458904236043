import { LoanSpecs } from "components/proformas/details";

export const calculateEquityData = (proforma: any, loansSpecs: LoanSpecs) => {
  const lastSale = proforma.parcel?.listing_history?.find((listing: any) => listing.event === "Sold");
  const lastSaleDate = lastSale?.date.slice(0, 10);
  const lastSalePrice = lastSale?.price || 0;

  const loansSpecsFiltered = loansSpecs.filter((loan, index) => {
    if (loan.Date < lastSaleDate) return false;
    if (loan["Loan Type"] === "Seller Financing") return index === 0;
    return true;
  });
  const marketValue = proforma.isDataComplete ? proforma.calcsheet?.current_value_estimate : 0;
  const totalOriginalPrincipals = loansSpecsFiltered.reduce((acc, cur) => acc + cur.Amount, 0);
  const totalRemainingPrincipals = loansSpecsFiltered.reduce(
    (acc, cur) => acc + cur["Remaining Principal"],
    0,
  );
  const totalPayDowns = totalOriginalPrincipals - totalRemainingPrincipals;
  const appreciation = lastSale ? marketValue - lastSalePrice : 0;
  const downPayment = marketValue - (totalPayDowns + appreciation + totalRemainingPrincipals);
  const equity = marketValue - totalRemainingPrincipals;

  const data =
    loansSpecsFiltered.length === 0
      ? [
          {
            name: "Last Sale",
            value: lastSalePrice,
            normalColor: "#578f81",
            hoverColor: "#467468",
          },
          {
            name: "Appreciation",
            value: appreciation,
            normalColor: "#4cb598",
            hoverColor: "#3f977e",
          },
        ]
      : downPayment < 0
        ? [
            {
              name: "Equity",
              value: equity,
              normalColor: "#4cb598",
              hoverColor: "#3f977e",
            },
            {
              name: "Paid Down",
              value: totalPayDowns,
              normalColor: "#578f81",
              hoverColor: "#467468",
            },
            {
              name: "Principal",
              value: totalRemainingPrincipals,
              normalColor: "#666666",
              hoverColor: "#4a4949",
            },
          ]
        : [
            {
              name: "Down Payment",
              value: downPayment,
              normalColor: "#578f81",
              hoverColor: "#467468",
            },
            {
              name: "Appreciation",
              value: appreciation,
              normalColor: "#4cb598",
              hoverColor: "#3f977e",
            },
            {
              name: "Paid Down",
              value: totalPayDowns,
              normalColor: "#578f81",
              hoverColor: "#467468",
            },
            {
              name: "Principal",
              value: totalRemainingPrincipals,
              normalColor: "#666666",
              hoverColor: "#4a4949",
            },
          ];

  return {
    lastSaleDate,
    lastSalePrice,
    loansSpecsFiltered,
    marketValue,
    totalOriginalPrincipals,
    totalRemainingPrincipals,
    totalPayDowns,
    appreciation,
    downPayment,
    equity,
    data,
  };
};
