import { Transition } from "@headlessui/react";
import { AdjustmentsIcon, XIcon } from "@heroicons/react/solid";
import { Spinner, WithSignIn } from "components";
import { getNewUrlParamsStrings } from "components/browse";
import { cn } from "helpers";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useUserSession } from "state";
import { locationSearchItemsAtom, searchResultsFiltersAtom, searchResultTotalCountAtom } from "state/browse";
import { NewSaveSearchType, useSavedSearchList } from "state/proformas";

interface Props {
  isPaneOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  numActiveFilters: number;
  onResetAllFilters: () => void;
  numFilteredProperties: number;
}

export const SlidingPanel = ({
  isPaneOpen,
  onClose,
  children,
  numActiveFilters,
  onResetAllFilters,
  numFilteredProperties,
}: Props) => {
  const filters = useRecoilValue(searchResultsFiltersAtom);
  const locationSearchItems = useRecoilValue(locationSearchItemsAtom);
  const totalSearchResults = useRecoilValue(searchResultTotalCountAtom);
  const location = useLocation();
  const currentUser = useUserSession();
  const [saving, setSaving] = useState<boolean>(false);
  const [newName, setNewName] = useState<string | null>(null);
  const { createSavedSearch } = useSavedSearchList(currentUser?.session?.user_id);

  const buildSavedFiltesParameters = () => {
    return getNewUrlParamsStrings(locationSearchItems, filters);
  };

  const onCreateNewSavedSearch = () => {
    const name = (newName || "").trim();
    if (name.length === 0) return;

    const newFilter: NewSaveSearchType = {
      name: name,
      active: false,
      parameters: {
        texts: buildSavedFiltesParameters(),
        url: location.pathname + location.search,
      },
    };

    setSaving(true);

    createSavedSearch(newFilter).finally(() => {
      setNewName(null);
      setSaving(false);
    });

    ReactGA.event({
      category: "Search",
      action: "Save from Browse",
      label: name,
    });
  };

  return (
    <Transition show={isPaneOpen}>
      <div className="fixed inset-0 z-50 h-full overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          {/* Background overlay */}
          <Transition.Child
            enter="transition-opacity ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-in duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="absolute inset-0 bg-gray-900 opacity-50" onClick={onClose}></div>
          </Transition.Child>

          {/* Sliding panel */}
          <Transition.Child
            enter="transition-transform ease-out duration-300"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transition-transform ease-in duration-300"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
            className="absolute inset-y-0 right-0 flex w-full max-w-xl"
          >
            {/* Your content goes here */}
            <div className="flex h-full w-full flex-col overflow-y-auto bg-white">
              <div className={cn("flex items-center justify-between p-4", !isMobile && "border-b py-4")}>
                {isMobile ? (
                  <AdjustmentsIcon className="mr-2 block size-5" aria-hidden="true" />
                ) : (
                  <span className="text-lg font-bold">All Filters</span>
                )}
                <button className="hover:text-st-normal text-st-lighter" onClick={onClose}>
                  <XIcon className="mr-2 block h-5 w-5" aria-hidden="true" />
                </button>
              </div>
              <div className={`${isMobile ? "mb-24" : "mb-32"} flex grow flex-col pb-2`}>{children}</div>

              <div className="absolute bottom-0 z-10 flex w-full flex-row items-center border-t bg-white p-4 px-6 text-center">
                {isMobile && (
                  <>
                    <div className="flex w-full flex-row justify-between">
                      <WithSignIn asButton={false} text="Login to save your searches">
                        <input
                          type="text"
                          className="mr-2 w-full grow border-x-0 border-b-2 border-t-0 border-b-black pb-1 pl-1 pr-0 text-sm focus:border-x-0 focus:border-t-0 focus:border-b-black"
                          placeholder="Name Your Search"
                          value={newName || ""}
                          onChange={(e) => setNewName(e.target.value)}
                        />
                        <button
                          className="text-st-darkest flex min-h-8 min-w-[60px] items-center justify-center whitespace-nowrap rounded-sm border border-black bg-white text-sm"
                          onClick={() => {
                            onCreateNewSavedSearch();
                          }}
                          disabled={!(newName || "").trim()}
                        >
                          {saving ? <Spinner className="text-st-darkest size-5" /> : <>Save</>}
                        </button>
                      </WithSignIn>
                      <button
                        className="ml-2 min-h-8 min-w-[60px] items-center justify-center whitespace-nowrap rounded-sm border border-black bg-black text-sm text-white"
                        onClick={onClose}
                      >
                        Search
                      </button>
                    </div>
                  </>
                )}
                {!isMobile && (
                  <div className="flex flex-row">
                    <button
                      className="items-center rounded-sm bg-[#6389c4] px-4 py-2 text-sm font-normal text-white shadow-lg transition duration-150 ease-in-out hover:bg-[#4674b9] sm:h-10 sm:px-8"
                      onClick={onClose}
                    >
                      Search
                    </button>
                  </div>
                )}
                <div>
                  {numActiveFilters > 0 && (
                    <button
                      className="hover:text-st-normal ml-4 whitespace-nowrap text-sm text-[#6389c4] hover:underline"
                      onClick={onResetAllFilters}
                    >
                      Reset
                      <br />
                      filters
                    </button>
                  )}
                </div>
                {!isMobile && (
                  <div className="w-full justify-end pt-1 text-right text-xs">
                    <span className="font-bold">{numFilteredProperties} matches</span> out of
                    <span className="font-bold"> {totalSearchResults}</span> listings
                  </div>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </div>
    </Transition>
  );
};
