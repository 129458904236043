import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, XIcon } from "@heroicons/react/outline";
import { PropertiesPriceSlider } from "components/browse";
import { formatCurrencyK1 } from "helpers";
import { Fragment } from "react";
import { isDesktop } from "react-device-detect";

type Props = {
  priceMin: number | null;
  priceMax: number | null;
  priceRangeSet: (min: number | null, max: number | null) => void;
};

export const FilterPriceMenu = ({ priceMin, priceMax, priceRangeSet }: Props) => {
  let buttonTittle = "";
  if (priceMin && priceMax) {
    buttonTittle = `${formatCurrencyK1(priceMin)} - ${formatCurrencyK1(priceMax)}`;
  } else if (priceMin) {
    buttonTittle = `${formatCurrencyK1(priceMin)}+`;
  } else if (priceMax) {
    buttonTittle = `Up to ${formatCurrencyK1(priceMax)}`;
  }

  return (
    <>
      {isDesktop && (
        <Menu as="div" className={`relative ml-2 mr-1 hidden filter-price:flex md:mx-2`}>
          {({ open }) => (
            <>
              <Menu.Button
                className={`text-md flex items-center justify-between rounded-md border border-gray-300 py-2 pl-3 pr-2 text-left text-sm font-medium ${
                  priceMin || priceMax ? "bg-black text-white" : "text-st-light"
                }`}
              >
                <span className="flex items-center whitespace-nowrap">
                  {priceMin || priceMax ? <>{buttonTittle}</> : <>Price</>}
                </span>
                {open ? (
                  <ChevronDownIcon className="ml-2 h-4 w-4" />
                ) : (
                  <>
                    {priceMin || priceMax ? (
                      <XIcon
                        className="ml-2 h-4 w-4"
                        onClick={(e) => {
                          e.stopPropagation();
                          priceRangeSet(null, null);
                        }}
                      />
                    ) : (
                      <ChevronDownIcon className="text-st-darkest ml-2 h-4 w-4" />
                    )}
                  </>
                )}
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute top-8 mt-2 w-[22rem] origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="w-full p-2 pb-4">
                    <PropertiesPriceSlider
                      priceMin={priceMin}
                      priceMax={priceMax}
                      priceRangeSet={priceRangeSet}
                      autoApply={false}
                    />
                  </div>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      )}
    </>
  );
};
