import { PeriodSelector } from "components";
import React, { useState } from "react";
import {
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useProforma, useRentalAppreciation } from "state/proformas";

const seriesColors = [
  { normalColor: "#777777", lightColor: "#777777" }, // County
  { normalColor: "#000000", lightColor: "#000000" }, // City || Metro
  { normalColor: "#45AB8F", lightColor: "#45AB8F" }, // Zip || Neighborhood
  { normalColor: "#FFA500", lightColor: "#FFA500" }, // Historical sales
];

export const RentalAppreciationGraph = () => {
  // const [selectedLine, setSelectedLine] = useState("");
  const { proforma } = useProforma();
  const lastListingPrice = proforma.parcel?.listing_history?.slice(0, 1);
  const lines: any[] = [];
  const { data, loading, error } = useRentalAppreciation(proforma.parcel_id);
  const [period, setPeriod] = useState(0);

  if (loading || error || !data || data.dates.length === 0) return null;

  const sqFtPricesCounty = data?.county_ppsqft?.slice(-period) || [];
  const sqFtPricesCity = data?.city_ppsqft?.slice(-period) || [];
  const sqFtPricesMetroArea = data?.metro_area_ppsqft?.slice(-period) || [];
  const sqFtPrices = data?.pp_sqft?.slice(-period) || [];
  const historicalSales = data?.historical_sales?.slice(-period) || [];
  const geo_attribute_for_appreciation = data?.geo_attribute_for_appreciation;
  const dates = data?.dates?.slice(-period) || [];
  const selectorValues = {
    "1Y": 13,
    "3Y": 37,
    "10Y": 121,
    All: 0,
  };

  // Build the graph title
  let graphTitle;
  if (data && data.structure_type_for_appreciation === "all") {
    graphTitle = "Median Sale";
  } else if (data.structure_type_for_appreciation === "Multifamily") {
    graphTitle = data.structure_type_for_appreciation + " Median Sale";
  } else {
    graphTitle = data.structure_type_for_appreciation + "s Median Sale";
  }

  // Build Legends
  const countyLegend = proforma.parcel?.county ? `${proforma.parcel?.county} Co.` : "";
  const cityLegend = proforma.parcel?.city ? `${proforma.parcel?._characteristics?.city_for_address}` : "";
  const metroAreaLegend = "Metro";
  var ppsqftLegend: string;
  if (proforma.parcel?.neighborhood && geo_attribute_for_appreciation === "neighborhood") {
    ppsqftLegend = `${proforma.parcel?.neighborhood?.toString()}`;
  } else if (proforma.parcel?.city && geo_attribute_for_appreciation === "city") {
    ppsqftLegend = `${proforma.parcel?.city?.toString()}`;
  } else {
    ppsqftLegend = "Zip";
  }
  // const locations = [countyLegend, cityLegend, ppsqftLegend];
  const locations = [countyLegend];
  if (sqFtPricesCity) locations.push(cityLegend);
  if (!sqFtPricesCity || geo_attribute_for_appreciation === "city") locations.push(metroAreaLegend);
  if (geo_attribute_for_appreciation !== "city") locations.push(ppsqftLegend);

  // Build Data object
  const newData = dates.map((date, index) => {
    let dateToShow = new Date(date);
    let salePrice = 0;
    let listingLastPriceForDate = false;

    const historicalSalesForDate = historicalSales.filter((sale) => {
      if (sale.sale_date && sale.sale_date.slice(0, 7) === date.slice(0, 7)) {
        dateToShow = new Date(sale.sale_date);
        salePrice = sale.sale_price;
        return true;
      }
      return false;
    });

    if (lastListingPrice[0]?.date.slice(0, 7) === date.slice(0, 7)) {
      dateToShow = new Date(lastListingPrice[0]?.date);
      salePrice = lastListingPrice[0].price;
      listingLastPriceForDate = true;
    } else {
      listingLastPriceForDate = false;
    }

    const row: any = {
      name: dateToShow,
    };

    if (historicalSalesForDate.length > 0) {
      row["Sale/Listing"] = ((salePrice / proforma.structure?.square_feet_finished) * 100) / 100;
    }

    if (listingLastPriceForDate) {
      row["Sale/Listing"] = ((salePrice / proforma.structure?.square_feet_finished) * 100) / 100;
    }

    row[countyLegend] =
      Number(
        Intl.NumberFormat("en-US", {
          useGrouping: false,
          maximumFractionDigits: 0,
        }).format(sqFtPricesCounty[index]),
      ) || "N/A";

    if (sqFtPricesCity) {
      row[cityLegend] =
        Number(
          Intl.NumberFormat("en-US", {
            useGrouping: false,
            maximumFractionDigits: 0,
          }).format(sqFtPricesCity[index]),
        ) || "N/A";
    }

    if (!sqFtPricesCity || geo_attribute_for_appreciation === "city") {
      row[metroAreaLegend] =
        Number(
          Intl.NumberFormat("en-US", {
            useGrouping: false,
            maximumFractionDigits: 0,
          }).format(sqFtPricesMetroArea[index]),
        ) || "N/A";
    }

    if (geo_attribute_for_appreciation !== "city") {
      row[ppsqftLegend] =
        Number(
          Intl.NumberFormat("en-US", {
            useGrouping: false,
            maximumFractionDigits: 0,
          }).format(sqFtPrices[index]),
        ) || "N/A";
    }

    return row;
  });

  // Add the last listing price to the data when its date is greater than the last date in the data
  if (new Date(lastListingPrice[0]?.date) > new Date(newData[newData.length - 1].name)) {
    newData.push({
      name: new Date(lastListingPrice[0]?.date),
      "Sale/Listing": ((lastListingPrice[0].price / proforma.structure?.square_feet_finished) * 100) / 100,
    });
  }

  // Build Lines
  locations.forEach((location, idx) => {
    lines.push(
      <Line
        type="natural"
        key={location}
        dataKey={location}
        stroke={seriesColors[idx].normalColor}
        legendType="plainline"
        strokeWidth={3.5}
        dot={false}
        activeDot={{
          r: 4,
        }}
      />,
    );
  });

  return (
    <>
      {!proforma.isRestError && (
        <>
          {!proforma.isDataComplete && proforma.isGeoData ? (
            <div className="h-[353px] w-full rounded-lg bg-gray-400/10 loading" />
          ) : (
            <>
              <div className="rounded-lg border border-stone-100 bg-[#ECF1F5] p-3">
                <div className="flex w-full flex-col">
                  <div className="text-st-darker pb-0 text-sm font-bold">{graphTitle}</div>
                  <div className="flex w-full flex-row">
                    <div className="w-full basis-1/2">
                      <div className="text-st-normal text-pretty pb-3 pt-0 text-sm font-light">
                        Price per Square Foot
                      </div>
                    </div>
                    <div className="items-right flex w-full basis-1/2 flex-col justify-end text-sm">
                      <div className="text-st-darker flex w-full flex-row pb-0 text-sm font-bold"></div>
                      <div className="text-st-normal w-full pb-3 pt-0 text-right text-sm font-light">
                        <PeriodSelector period={period} value={selectorValues["1Y"]} setPeriod={setPeriod} />
                        <PeriodSelector period={period} value={selectorValues["3Y"]} setPeriod={setPeriod} />
                        <PeriodSelector period={period} value={selectorValues["10Y"]} setPeriod={setPeriod} />
                        <PeriodSelector period={period} value={selectorValues["All"]} setPeriod={setPeriod} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="h-full w-full text-xs">
                  {newData.length > 0 && (
                    <ResponsiveContainer aspect={4 / 3}>
                      <ComposedChart
                        data={newData}
                        margin={{
                          top: 10,
                          right: 5,
                          left: -20,
                          bottom: 0,
                        }}
                      >
                        <CartesianGrid vertical={false} syncWithTicks={true} opacity={0.3} />
                        <XAxis
                          type="category"
                          opacity={0.4}
                          dataKey="name"
                          tickMargin={5}
                          tickSize={3}
                          tickFormatter={(value) => {
                            let dateFormat: any;
                            if (period === selectorValues["1Y"] || period === selectorValues["3Y"]) {
                              dateFormat = new Intl.DateTimeFormat("en-US", {
                                month: "short",
                              }).format(value);
                            } else {
                              dateFormat = new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                              }).format(value);
                            }
                            return dateFormat;
                          }}
                          interval={"equidistantPreserveStart"}
                          axisLine={true}
                        />

                        <YAxis
                          tickMargin={5}
                          tickSize={0}
                          domain={["auto", "auto"]}
                          tickFormatter={(value) => `$${value}`}
                          axisLine={true}
                          opacity={0.4}
                          tickCount={10}
                        />

                        <Tooltip
                          offset={50}
                          labelFormatter={(value: any) => {
                            return (
                              <span className="text-[11px] font-bold">
                                {new Intl.DateTimeFormat("en-US", {
                                  year: "numeric",
                                  month: "short",
                                  day: "2-digit",
                                }).format(new Date(value))}
                              </span>
                            );
                          }}
                          formatter={(value: any, name: any, props: any) => {
                            return [
                              <ul className="list-inside">
                                <li key={name} className="list-disc text-[11px] leading-none">
                                  {name}:{" "}
                                  <span className="text-st-normal font-bold">${Math.round(value)}/sqft</span>
                                </li>
                              </ul>,
                            ];
                          }}
                        />
                        {lines}
                        <Scatter
                          name="Sale"
                          dataKey={"Sale/Listing"}
                          fill={seriesColors[3].lightColor}
                          stroke={seriesColors[3].normalColor}
                          legendType="circle"
                        />
                        <Legend
                          verticalAlign="bottom"
                          wrapperStyle={{
                            bottom: 0,
                            left: 0,
                            right: 0,
                            position: "absolute",
                            display: "flex",
                            justifyContent: "center",
                            paddingTop: "10px",
                          }}
                          iconSize={10}
                        />
                      </ComposedChart>
                    </ResponsiveContainer>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
