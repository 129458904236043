import { Control, Controller, FieldValues, UseFormSetValue } from "react-hook-form";
import NumberFormat from "react-number-format";

type Props = {
  label: string;
  variable: string;
  isCurrency?: boolean;
  isPercentage?: boolean;
  control: Control<FieldValues, unknown>;
  handleChange: (e: any) => void;
  setValue: UseFormSetValue<FieldValues>;
};

export const MinMaxFilterInputs = ({
  label,
  variable,
  isCurrency,
  isPercentage,
  control,
  handleChange,
  setValue,
}: Props) => {
  const commonClasses =
    "flex h-5 p-0 w-full text-right bg-transparent placeholder:text-st-lightest border-b border-dotted border-gray-400 items-center border border-gray-400 px-2 py-4 text-right";

  return (
    <div className="mt-2 flex w-full flex-row items-center justify-between gap-4">
      <div className="flex w-5/12 whitespace-nowrap">{label}</div>
      <div className="flex w-7/12 flex-row gap-4">
        <div className="flex">
          <Controller
            control={control}
            name={`min_${variable}`}
            render={({ field: { onChange, name, value } }) => (
              <NumberFormat
                className={commonClasses}
                name={name}
                defaultValue={value}
                placeholder="Min"
                prefix={isCurrency ? "$ " : undefined}
                suffix={isPercentage ? " %" : undefined}
                decimalScale={isPercentage ? undefined : 0}
                thousandSeparator={variable === "year_built" ? false : true}
                displayType="input"
                onValueChange={(e: any) => {
                  onChange(e);
                  setValue(name, e.floatValue);
                  handleChange(e);
                }}
              />
            )}
          />
        </div>
        <div className="text-st-lightest py-1">-</div>

        <div className="flex">
          <Controller
            control={control}
            name={`max_${variable}`}
            render={({ field: { onChange, name, value } }) => (
              <NumberFormat
                className={commonClasses}
                name={name}
                defaultValue={value}
                placeholder="Max"
                prefix={isCurrency ? "$ " : undefined}
                suffix={isPercentage ? " %" : undefined}
                decimalScale={isPercentage ? undefined : 0}
                thousandSeparator={variable === "year_built" ? false : true}
                displayType="input"
                onValueChange={(e: any) => {
                  onChange(e);
                  setValue(name, e.floatValue);
                  handleChange(e);
                }}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};
